import React, {useEffect, useState} from 'react';
import {Linking, StyleSheet, TouchableOpacity, View} from 'react-native';
import HGTextInput from 'components/HGTextInput';
import {scale} from '../../../utils/Scale';
import {useTranslation} from 'react-i18next';
import Colors from '../../../utils/Colors';
import HGText from '../../../components/HGText';
import HGCheckBox from 'components/HGCheckBox';
import {Eye} from '../../../components/HGIcon';
import TealContainer from 'app/components/TealContainer';
import config from 'app/config';

const LoginBox = ({onChange}) => {
  const {t} = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [autoSign, setAutoSign] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange({username, password, autoSign});
    }
  }, [username, password, autoSign, onChange]);

  return (
    <TealContainer>
      <HGTextInput
        value={username}
        onChange={setUsername}
        variant="form"
        label={t('email')}
      />
      <HGTextInput
        value={password}
        onChange={setPassword}
        inputProps={{secureTextEntry: !showPassword}}
        variant="form"
        label={t('Password')}
        labelEndComponent={() => (
          <TouchableOpacity
            onPress={() => Linking.openURL(config.FORGOTTEN_PASSWORD_URL)}>
            <HGText style={styles.forgetPassword}>
              {t('Forgotten Password')}
            </HGText>
          </TouchableOpacity>
        )}
        inputEndComponent={() => (
          <TouchableOpacity
            hitSlop={{top: 10, left: 10, right: 10, bottom: 10}}
            onPress={() => {
              setShowPassword(!showPassword);
            }}>
            <Eye style={styles.eye} />
          </TouchableOpacity>
        )}
      />
      <HGCheckBox
        value={autoSign}
        onChange={setAutoSign}
        label={t('Remain signed in')}
      />
    </TealContainer>
  );
};

export default LoginBox;

const styles = StyleSheet.create({
  forgetPassword: {
    fontSize: scale(12),
    color: Colors.RED,
  },
  eye: {
    width: 24,
    height: 16,
  },
});
