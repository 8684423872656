import React, {useCallback, useRef} from 'react';

import {
  StyleSheet,
  View,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import {scale} from '../../utils/Scale';
import HGText from 'components/HGText';
import Colors from '../../utils/Colors';
import Row from 'components/Row';
import {CheckboxUnchecked, CheckboxChecked} from '../HGIcon';

const HGCheckBox = ({label, variant, value, onChange}) => {
  const rootStyle = [];
  switch (variant) {
  }

  const onPressLabel = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  return (
    <View style={rootStyle}>
      <TouchableWithoutFeedback onPress={onPressLabel}>
        <Row style={styles.row}>
          {value ? (
            <CheckboxChecked style={styles.checkbox} />
          ) : (
            <CheckboxUnchecked style={styles.checkbox} />
          )}
          {label && (
            <TouchableWithoutFeedback onPress={onPressLabel}>
              <View style={styles.labelContainer}>
                <HGText style={styles.label}>{label}</HGText>
              </View>
            </TouchableWithoutFeedback>
          )}
        </Row>
      </TouchableWithoutFeedback>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {alignItems: 'center'},
  emptySeparator: {flex: 1},
  formRootContainer: {
    marginBottom: scale(15),
  },
  label: {
    fontSize: scale(12),
  },
  labelContainer: {
    flexDirection: 'row',
    marginLeft: scale(18),
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: scale(18),
    borderWidth: 0.25,
    borderColor: Colors.SECONDARY_COLOR,
    backgroundColor: '#FFF',
    height: scale(36),
    paddingHorizontal: scale(16),
  },
  input: {height: scale(36)},
  checkbox: {
    width: scale(24),
    height: scale(24),
  },
});

export default HGCheckBox;
