import React from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';

export default ({body, actionText, containerStyle, actionTextOnPress}) => (
  <View style={[styles.container, containerStyle]}>
    {typeof body === 'function' ? body() : <HGText>{body}</HGText>}
    {actionText && (
      <TouchableOpacity onPress={actionTextOnPress || (() => {})}>
        <HGText style={styles.actionText} variant="actionText">
          {actionText}
        </HGText>
      </TouchableOpacity>
    )}
  </View>
);

const styles = StyleSheet.create({
  container: {
    padding: scale(19),
    borderColor: Colors.PRIMARY_COLOR,
    borderWidth: 1,
    borderLeftWidth: scale(8),
    backgroundColor: '#FFF',
    borderRadius: scale(8),
  },
  actionText: {
    marginTop: scale(33),
  },
});
