import React, {useEffect, useState} from 'react';
import {Camera} from 'expo-camera';
import {Platform} from 'react-native';
export default () => {
  const [initialized, setInitialized] = useState(false);
  const [permission, setPermission] = useState(false);
  const [gettingPermission, setGettingPermission] = useState(true);

  async function getPermission() {
    setGettingPermission(true);
    try {
      try {
        const {status: currentPermission} =
          await Camera.getCameraPermissionsAsync();
        if (currentPermission === 'granted') {
          setPermission(true);
          return;
        }
      } catch (err) {
        // Safari iOS does not have getCameraPermissionsAsync function.
      }
      // [WEB] this already opens a stream so if status has yet been granted this will open the camera already. we thus need to check above first if its already granted do not invoke again.
      const {status} = await Camera.requestCameraPermissionsAsync();
      setPermission(status === 'granted');
    } catch (err) {
    } finally {
      setGettingPermission(false);
    }
  }

  useEffect(() => {
    if (!initialized) {
      getPermission();
      setInitialized(true);
    }
  }, [initialized]);

  return {permission, gettingPermission};
};
