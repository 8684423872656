import React from 'react';
import {scale} from 'app/utils/Scale';
import {parseDisplayDate} from 'app/utils/Utils';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import Card from '.';
import HGText from '../HGText';
import Row from '../Row';
import {Connected} from 'components/HGIcon';

export default ({title, date, image, admission, startAt, isSelected}) => {
  const {t, i18n} = useTranslation();

  return (
    <Card
      largeImage={true}
      title={title}
      subtitle={
        <Row>
          <HGText variant="eventDetailsMeta" color="highlight">
            {parseDisplayDate(date)}
          </HGText>
          <HGText
            variant="eventDetailsMeta"
            color="highlight"
            style={styles.dateTimeDivider}>
            |
          </HGText>
          <HGText
            variant="eventDetailsMeta"
            color="highlight"
            style={styles.label}>
            {startAt}
            {i18n.language === 'de' ? ' Uhr' : ''}
          </HGText>
          {isSelected ? (
            <View style={styles.containerIconConnected}>
              <Connected style={styles.iconConnected} />
            </View>
          ) : null}
        </Row>
      }
      image={image}
      containerStyle={styles.card}
      footerText={`${t('admission')}: ${admission}${
        i18n.language === 'de' ? ' Uhr' : ''
      }`}
      isHideImage={isSelected ? true : false}
    />
  );
};

const styles = StyleSheet.create({
  card: {
    marginBottom: scale(20),
  },
  dateTimeDivider: {
    marginHorizontal: scale(8),
  },
  containerIconConnected: {
    alignItems: 'flex-end',
    flex: 1,
  },
  iconConnected: {
    width: scale(14),
    height: scale(14),
  },
});
