import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';

const HGButton = ({
  label,
  onPress,
  icon = null,
  variant = 'primary',
  size = 'large',
  loading = false,
  disabled = false,
  fullWidth = false,
  upperCase = true,
  labelColor,
  TouchableComponent,
  TouchableComponentProps,
}) => {
  const TouchComponent = TouchableComponent || TouchableOpacity;

  return (
    <TouchComponent
      disabled={loading || disabled}
      style={[{flexDirection: 'row'}, fullWidth ? {width: '100%'} : {}]}
      onPress={onPress || (() => {})}
      {...TouchableComponentProps}>
      <View
        style={[
          size === 'large' ? styles.largeContainer : styles.smallContainer,
          variant === 'primary'
            ? styles.primaryContainer
            : styles.secondaryContainer,
          fullWidth ? {width: '100%', minWidth: 0} : {},
          disabled || loading
            ? variant === 'primary'
              ? styles.disabledPrimaryContainer
              : styles.disabledSecondaryContainer
            : {},
        ]}>
        {loading ? (
          <ActivityIndicator
            color={variant === 'primary' ? 'white' : Colors.PRIMARY_COLOR}
          />
        ) : (
          <Row style={styles.labelContainer}>
            {icon && <View style={styles.labelIcon}>{icon}</View>}
            <HGText
              style={[
                styles.label,
                variant === 'primary'
                  ? styles.primaryLabel
                  : styles.secondaryLabel,
                labelColor
                  ? {
                      color:
                        labelColor === 'primary'
                          ? Colors.PRIMARY_COLOR
                          : labelColor === 'secondary'
                          ? Colors.SECONDARY_COLOR
                          : labelColor,
                    }
                  : {},
              ]}>
              {upperCase ? label.toUpperCase() : label}
            </HGText>
          </Row>
        )}
      </View>
    </TouchComponent>
  );
};

const styles = StyleSheet.create({
  labelContainer: {
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: scale(11),
  },
  label: {
    ...{
      fontSize: scale(17),
    },
    ...(Platform.OS === 'web'
      ? {}
      : {
          lineHeight: scale(17) * 1.6,
          height: scale(17),
        }),
  },
  primaryLabel: {
    color: '#FFF',
  },
  secondaryLabel: {
    color: Colors.SECONDARY_COLOR,
  },
  smallContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(13),
    paddingHorizontal: scale(31),
    minWidth: scale(140),
    borderRadius: scale(23),
  },
  largeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(15),
    paddingHorizontal: scale(35),
    minWidth: scale(158),
    borderRadius: scale(26),
  },
  primaryContainer: {
    backgroundColor: Colors.SECONDARY_COLOR,
  },
  secondaryContainer: {
    borderWidth: 1,
    borderColor: Colors.SECONDARY_COLOR,
    backgroundColor: '#FFF',
  },
  disabledPrimaryContainer: {
    backgroundColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
  },
  disabledSecondaryContainer: {
    backgroundColor: hexToRgba('#000000', 0.05),
  },
});

export default HGButton;
