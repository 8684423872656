import {createSlice} from '@reduxjs/toolkit';
import config from '../../config';
import {} from './helpers';
import {logoutUser} from '../account';

const initialState = {
  loading: false,
  initialize: false,
  events: [],
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.initialize = action.payload;
    },
    initialize: (state, action) => {
      state.events = action.payload.events;
      state.categories = action.payload.categories;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const actions = eventsSlice.actions;

const fetchData = async ({accessToken, tenantId}) => {
  const res = await fetch(`${config.BASE_URL}/events`, {
    headers: {'X-AUTH-TOKEN': accessToken, 'X-TENANT-ID': tenantId},
  });
  const json = await res.json();
  return json;
};

export const initializeData = navigation => async (dispatch, getState) => {
  dispatch(actions.setLoading(true));
  dispatch(actions.setInitialized(true));
  const state = getState();
  try {
    const events = await fetchData({
      accessToken: state.account.accessToken,
      tenantId: state.account.tenantId,
    });
    dispatch(
      actions.initialize({
        events: events.map(
          ({title, content, ...event}) => ({
            title:
              title && Object.keys(title).length > 0
                ? title
                : {de: '-', en: '-'},
            content:
              content && Object.keys(content).length > 0
                ? content
                : {de: '-', en: '-'},
            ...event,
          }),
          // events: events.filter(
          //   ({title, content}) =>
          //     title &&
          //     Object.keys(title).length > 0 &&
          //     content &&
          //     Object.keys(content).length > 0,
        ),
      }),
    );
  } catch (err) {
    // accessToken is invalid? server is down?
    if (navigation) {
      dispatch(logoutUser());
      navigation.replace('Login');
    }
  }
  dispatch(actions.setLoading(false));
};

export default eventsSlice.reducer;
