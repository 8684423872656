import React, {useEffect, useState} from 'react';
import Container from 'app/components/Container';
import getEventDetails from 'app/hooks/getEventDetails';
import {StyleSheet, View, ScrollView} from 'react-native';
import FullWidthEventCard from 'app/components/Card/FullWidthEventCard';
import {scale} from 'app/utils/Scale';
import HGText from 'app/components/HGText';
import {useTranslation} from 'react-i18next';
import BasicLoadingIndicator from 'app/components/BasicLoadingIndicator';
import HGButton from 'app/components/HGButton';

export default ({route, navigation}) => {
  const {t, i18n} = useTranslation();
  const {event, loading} = getEventDetails({eventId: route.params.eventId});
  const currentLang = i18n.language;

  const extractEventProps = event => {
    const {
      title,
      date_at: date,
      assets,
      start_at: startAt,
      admission_at: admission,
      id: eventId,
    } = event;
    const image = assets[0]?.public_path ?? ''; //TODO: DEFAULT 404 NO FILE HERE
    return {title: title[currentLang], date, image, admission, startAt};
  };
  return (
    <Container>
      <ScrollView>
        {loading && <BasicLoadingIndicator />}
        {event && (
          <View style={styles.container}>
            <HGText style={styles.text}>
              {t('You are connected to the following event')}:
            </HGText>
            <FullWidthEventCard
              {...extractEventProps(event)}
              isSelected={true}
            />
            <View style={styles.buttonContainer}>
              <HGButton
                label={t('Scan QR code')}
                onPress={() =>
                  navigation.navigate('EventQrScanner', {
                    eventId: route.params.eventId,
                  })
                }
                fullWidth={true}
                upperCase={false}
              />
            </View>
          </View>
        )}
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(36),
  },
  text: {
    marginBottom: scale(13),
    color: '#61c6d2',
  },
  buttonContainer: {
    marginTop: scale(160),
    marginBottom: scale(75),
    alignItems: 'center',
    flex: 1,
  },
});
