import React from 'react';
import {View, StyleSheet} from 'react-native';

export default ({children, style, ...props}) => (
  <View style={[styles.container, style]} {...props}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});
