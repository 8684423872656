import React, {useEffect} from 'react';
import './assets/fonts/BalooBhaina2-Regular.ttf';
import './assets/fonts/BalooBhaina2-Bold.ttf';
import './assets/web/styles.css';

import App from './App.js';
export default props => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then(registration => {
            console.log('sw success');
          })
          .catch(err => {
            console.log('sw fail');
          });
      });
    }
  });

  return <App {...props} />;
};
