import {CardContainer} from 'app/components/Card';
import HGCheckBox from 'app/components/HGCheckBox';
import HGText from 'app/components/HGText';
import Colors from 'app/utils/Colors';
import {scale} from 'app/utils/Scale';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

export default ({checked, onCheck}) => {
  const {t} = useTranslation();
  return (
    <CardContainer
      padded={true}
      containerStyle={{backgroundColor: '#FFF', borderWidth: 0}}>
      <View style={styles.container}>
        <View style={styles.title}>
          <HGText variant="header" upperCase={true}>
            {t('contact details')}
          </HGText>
        </View>
        <View style={styles.message}>
          <HGText fontSize={scale(17)} color={'#808080'}>
            {t('No contact details available')}
          </HGText>
        </View>
        <View>
          <HGCheckBox
            onChange={onCheck}
            value={checked}
            largeLabel={true}
            label={t('visually sighted')}
            labelStyle={{color: Colors.PRIMARY_COLOR}}
          />
        </View>
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    marginBottom: scale(42),
  },
  message: {
    marginBottom: scale(42),
  },
});
