import React, {useRef} from 'react';
import {StyleSheet, View, TouchableOpacity, ScrollView} from 'react-native';
import {heightPercentageToDP, scale} from '../../utils/Scale';
import HGText from 'components/HGText';
import Colors from '../../utils/Colors';
import {useTranslation} from 'react-i18next';
import * as HGIcon from 'components/HGIcon';
import useModal from 'hooks/useModal';
import Row from 'components/Row';
import {CardContainer} from 'components/Card';
import hexToRgba from 'hex-to-rgba';
import {Controller} from 'react-hook-form';

const ErrorMessage = ({type, message}) => {
  const {t} = useTranslation();
  const getErrorMessage = () => {
    switch (type) {
      case 'required':
        return t('errors.required');
      default:
        return t('errors.default');
    }
  };

  return (
    <HGText style={styles.errorContainer} variant="error">
      {message || getErrorMessage()}
    </HGText>
  );
};

const OptionsList = ({options, selected, onChange, disableTranslation}) => {
  const {t} = useTranslation();
  return (
    <View style={styles.optionsMainContainer}>
      <ScrollView>
        {options.map(({value, label}) => (
          <TouchableOpacity
            key={`option-${value}`}
            onPress={() => {
              onChange(value);
            }}
            style={[
              styles.optionContainer,
              value === selected ? styles.optionSelectedContainer : {},
            ]}>
            <Row>
              <HGText>{disableTranslation ? label : t(label)}</HGText>
            </Row>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const SelectInput = ({
  label,
  variant,
  labelEndComponent,
  onChange: _onChange,
  value,
  placeholder,
  options,
  form,
  error,
  disableTranslation,
  inputProps = {},
}) => {
  const {t} = useTranslation();
  function onChange(val) {
    _onChange && _onChange(val);
  }

  const rootStyle = [];
  switch (variant) {
    case 'form':
      rootStyle.push(styles.formRootContainer);
  }

  const {showModal} = useModal();

  const getOptionsList = () =>
    form ? (
      <Controller
        {...form}
        render={({field: {onChange, value}}) => (
          <OptionsList
            disableTranslation={disableTranslation}
            options={options}
            selected={value}
            onChange={onChange}
          />
        )}
      />
    ) : (
      <OptionsList
        disableTranslation={disableTranslation}
        options={options}
        selected={value}
        onChange={onChange}
      />
    );

  const renderLabel = () =>
    options.reduce((a, b) => (b.value === value ? b : a), '')?.label;

  return (
    <View style={rootStyle}>
      {label && (
        <View style={styles.labelContainer}>
          <HGText variant="inputLabel">{label}</HGText>
          {labelEndComponent && (
            <>
              <View style={styles.emptySeparator} />
              {labelEndComponent()}
            </>
          )}
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          showModal({title: label, component: getOptionsList});
        }}>
        <View
          style={[
            styles.inputContainer,
            error ? styles.errorInputContainer : {},
          ]}>
          {form ? (
            <HGText>
              {disableTranslation ? renderLabel() : t(renderLabel())}
            </HGText>
          ) : (
            <>
              {placeholder && !value && (
                <HGText color="#707070">{placeholder}</HGText>
              )}
              {value && <HGText>{value}</HGText>}
            </>
          )}
          <View style={styles.emptySeparator} />
          <HGIcon.RightChevron style={styles.chevron} />
        </View>
      </TouchableOpacity>
      {error ? (
        <ErrorMessage type={error.type} message={error.message} />
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  emptySeparator: {flex: 1},
  formRootContainer: {
    marginBottom: scale(15),
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
    marginBottom: scale(6),
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: scale(18),
    borderWidth: 0.25,
    borderColor: Colors.SECONDARY_COLOR,
    backgroundColor: '#FFF',
    height: scale(36),
    paddingHorizontal: scale(16),
  },
  errorInputContainer: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_COLOR,
  },
  chevron: {
    height: scale(17),
    width: scale(8),
    transform: [{rotate: '-90deg'}],
  },
  optionsMainContainer: {
    maxHeight: heightPercentageToDP(25),
  },
  optionContainer: {
    flex: 1,
    minHeight: 36,
    marginBottom: scale(15),
    paddingHorizontal: scale(16),
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionSelectedContainer: {
    borderWidth: 1,
    borderColor: hexToRgba(Colors.PRIMARY_COLOR, 0.25),
    borderRadius: scale(10),
  },
});

export default SelectInput;
