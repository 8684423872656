import config from 'app/config';
import {useState} from 'react';
import {useSelector} from 'react-redux';

export default () => {
  const {accessToken, tenantId} = useSelector(state => state.account);
  const [loading, setLoading] = useState(false);

  const validateTicket = async ({
    approve,
    userId,
    ticketId,
    eventId,
    visuallySighted = false,
  }) => {
    setLoading(true);

    let body = {
      event_id: eventId,
      visually_sighted: visuallySighted,
    };

    if (userId) {
      body = {...body, user_account_id: userId};
    }

    if (ticketId) {
      body = {...body, order_item_uuid: ticketId};
    }

    try {
      const res = await fetch(
        `${config.ENDPOINT_VALIDATE_TICKET}/${
          approve ? 'accepted' : 'rejected'
        }`,
        {
          method: 'POST',
          headers: {
            'X-AUTH-TOKEN': accessToken,
            'X-TENANT-ID': tenantId,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
      );
      const json = await res.json();
      return {success: res.status === 200, results: json};
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {loading, validateTicket};
};
