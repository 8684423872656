import {DateTime} from 'luxon';

export function ordinal(n) {
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function parseDisplayDate(date) {
  return DateTime.fromJSDate(new Date(date)).toFormat(`EEE, d. MMM yyyy`);
}

export function parseCurrency({amount, currency = 'EUR', locale = 'de'}) {
  const localeMap = {
    de: 'de-DE',
  };
  return `${new Intl.NumberFormat(localeMap[locale], {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(amount)}`;
}
