import React from 'react';
import {Image, StyleSheet} from 'react-native';

export const BarCupFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/bar-cup-fill.svg')}
  />
);
export const BarCup = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/bar-cup.svg')}
  />
);
export const Bar = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/bar.svg')}
  />
);
export const Bell = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/bell.svg')}
  />
);
export const Calendar = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/calendar.svg')}
  />
);
export const CameraFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/camera-fill.svg')}
  />
);
export const Camera = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/camera.svg')}
  />
);
export const CheckboxUnchecked = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/checkbox-unchecked.svg')}
  />
);
export const CheckboxChecked = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/checkbox-checked.svg')}
  />
);
export const Clipboard = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/clipboard.svg')}
  />
);
export const CopyFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/copy-fill.svg')}
  />
);
export const CovidCertificate = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/covid-certificate.svg')}
  />
);
export const Delete = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/delete.svg')}
  />
);
export const Document = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/document.svg')}
  />
);
export const Download = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/download.svg')}
  />
);
export const Edit = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/edit.svg')}
  />
);
export const Euro = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/euro.svg')}
  />
);
export const Folder = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/folder.svg')}
  />
);
export const HeartFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/heart-fill.svg')}
  />
);
export const Heart = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/heart.svg')}
  />
);
export const HomeFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/home-fill.svg')}
  />
);
export const Home = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/home.svg')}
  />
);
export const HospitalCertificate = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/hospital-certificate.svg')}
  />
);
export const IdentityCard = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/identity-card.svg')}
  />
);
export const LeftChevron = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/left-cheron.svg')}
  />
);
export const HeaderBack = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/header-back.svg')}
  />
);
export const RightChevron = ({style, ...props}) => (
  <Image
    style={[
      styles.icon,
      {
        transform: [{rotateY: '180deg'}],
      },
      style,
    ]}
    {...props}
    source={require('./svgs/left-cheron.svg')}
  />
);
export const DownChevron = ({style, ...props}) => (
  <Image
    style={[
      styles.icon,
      {
        transform: [{rotate: '-90deg'}],
      },
      style,
    ]}
    {...props}
    source={require('./svgs/left-cheron.svg')}
  />
);
export const Lightning = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/lightning.svg')}
  />
);
export const Lock = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/lock.svg')}
  />
);
export const MinusCircle = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/minus-circle.svg')}
  />
);
export const MinusCircleRed = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/minus-circle-red.svg')}
  />
);
export const More = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/more.svg')}
  />
);
export const PlusCircle = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/plus-circle.svg')}
  />
);
export const PlusCircleRed = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/plus-circle-red.svg')}
  />
);
export const Print = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/print.svg')}
  />
);
export const Qr = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/qr.svg')}
  />
);
export const QuestionCircle = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/question-circle.svg')}
  />
);
export const Settings = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/settings.svg')}
  />
);
export const Share = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/share.svg')}
  />
);
export const Shield = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/shield.svg')}
  />
);
export const ShoppingCart = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/shopping-cart.svg')}
  />
);
export const ThumbsUp = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/thumbs-up.svg')}
  />
);
export const TicketFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/ticket-fill.svg')}
  />
);
export const TicketFill1 = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/ticket-fill1.svg')}
  />
);
export const Ticket = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/ticket.svg')}
  />
);
export const TicketsFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/tickets-fill.svg')}
  />
);
export const Time = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/time.svg')}
  />
);
export const Upload = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/upload.svg')}
  />
);
export const UserDetails = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/user-details.svg')}
  />
);
export const UserFill = ({style, ...props}) => (
  <Image
    style={[styles.icon, {width: 19.22, height: 22}, style]}
    {...props}
    source={require('./svgs/user-fill.svg')}
  />
);
export const User = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/user.svg')}
  />
);
export const UserRed = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/user-red.svg')}
  />
);
export const Search = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/search.svg')}
  />
);

export const Cross = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/cross.svg')}
  />
);
export const CrossFillRed = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/cross-fill-red.svg')}
  />
);
export const BlackCross = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/black-cross.svg')}
  />
);
export const ExclamationCircle = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/red-exclamation.svg')}
  />
);
export const GreenCheckCircle = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/green-check.svg')}
  />
);
export const ArrowSwap = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/arrow-swap.svg')}
  />
);

export const Eye = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/eye.svg')}
  />
);
export const Check = ({style, ...props}) => (
  <Image
    style={[styles.icon, {width: 26.486, height: 20.292}, style]}
    {...props}
    source={require('./svgs/check.svg')}
  />
);
export const Logout = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/logout.svg')}
    />
);
export const Connected = ({style, ...props}) => (
  <Image
    style={[styles.icon, style]}
    {...props}
    source={require('./svgs/connected.svg')}
  />
);

const styles = StyleSheet.create({
  icon: {width: 32, height: 32},
});
