import {Check, CrossFillRed, GreenCheckCircle} from 'app/components/HGIcon';
import HGText from 'app/components/HGText';
import Row from 'app/components/Row';
import {scale} from 'app/utils/Scale';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

export default ({checked, accepted}) => {
  const {t} = useTranslation();
  return (
    <View>
      <Row style={styles.headerContainer}>
        <View style={styles.circleContainer}>
          {checked && accepted ? (
            <View style={styles.iconContainer}>
              <Check style={styles.icon} />
            </View>
          ) : accepted ? (
            <View>
              <CrossFillRed style={styles.crossIcon} />
            </View>
          ) : (
            <View style={styles.blankIcon} />
          )}
        </View>
        <Row style={styles.headerContainer}>
          <HGText bold={true} fontSize={scale(24)}>
            {t('Contact details')}
          </HGText>
        </Row>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
  },
  circleContainer: {
    marginRight: scale(45),
  },
  iconContainer: {
    width: scale(40),
    height: scale(40),
    borderRadius: scale(20),
    backgroundColor: '#1F791B',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 31.3,
    height: 24,
  },
  crossIcon: {
    width: scale(40),
    height: scale(40),
  },
  blankIcon: {
    width: scale(40),
    height: scale(40),
    borderRadius: scale(20),
    backgroundColor: '#808080',
  },
});
