import {CardContainer} from 'app/components/Card';
import {
  CovidCertificate,
  Document,
  HospitalCertificate,
} from 'app/components/HGIcon';
import HGText from 'app/components/HGText';
import Row from 'app/components/Row';
import {scale} from 'app/utils/Scale';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, Linking, StyleSheet, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';

export default ({documents, navigation}) => {
  const {t} = useTranslation();

  const getTitle = documentType => {
    switch (documentType) {
      case 'health_covid_vacc':
        return t('vaccination certificate');
      case 'health_covid_reco':
        return t('recovery');
      case 'health_covid_test':
        return t('corona test');
      default:
        return false;
    }
  };
  const getIcon = documentType => {
    switch (documentType) {
      case 'health_covid_vacc':
        return <CovidCertificate style={styles.imageThumbnail} />;
      case 'health_covid_reco':
        return <Document style={styles.imageThumbnail} />;
      case 'health_covid_test':
        return <HospitalCertificate style={styles.imageThumbnail} />;
      default:
        return false;
    }
  };
  return (
    <CardContainer
      padded={true}
      containerStyle={{backgroundColor: '#FFF', borderWidth: 0}}>
      <View style={styles.container}>
        <View style={styles.title}>
          <HGText variant="header" upperCase={true}>
            {t('documents')}
          </HGText>
        </View>
        <View>
          {documents
            .filter(({document_type}) => !!getTitle(document_type))
            .map(({document_type: type, content_type, url}, i) => {
              const isImage = content_type.indexOf('image') >= 0;
              const title = getTitle(type);
              return (
                <TouchableOpacity
                  key={i}
                  onPress={() =>
                    isImage
                      ? navigation.push('ImageDocumentViewer', {
                          title,
                          file: url,
                        })
                      : Linking.openURL(url)
                  }>
                  <Row style={styles.documentContainer}>
                    {isImage ? (
                      <Image
                        source={{uri: url}}
                        style={styles.imageThumbnail}
                      />
                    ) : (
                      getIcon(type)
                    )}
                    <HGText fontSize={scale(17)}>{title}</HGText>
                  </Row>
                </TouchableOpacity>
              );
            })}
        </View>
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    marginBottom: scale(20),
  },
  documentContainer: {
    marginBottom: scale(14),
    alignItems: 'center',
  },
  imageThumbnail: {
    width: scale(40),
    height: scale(40),
    marginRight: scale(22),
  },
});
