import useAccount from 'app/hooks/useAccount';
import React, {useEffect} from 'react';
import {View, Image, StyleSheet} from 'react-native';

export default ({navigation, disableRedirect = false}) => {
  const {account, loading} = useAccount();

  useEffect(() => {
    if (disableRedirect) return;

    setTimeout(() => {
      navigation && navigation.replace(account.loggedIn ? 'Home' : 'Login');
    }, 1000);
  }, [navigation, disableRedirect, account]);

  return (
    <View style={styles.container}>
      <Image
        resizeMode="contain"
        style={styles.logoImage}
        source={require('../../../assets/images/logo.png')}
      />
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  logoImage: {
    width: '100%',
    alignSelf: 'center',
  },
});
