import {Dimensions, PixelRatio, Platform} from 'react-native';

const {width, height} = Dimensions.get('window');
const fontScale = PixelRatio.getFontScale();
// Guideline sizes are based on standard ~5" screen mobile device 1024x768,
let guidelineBaseWidth = 350;

if (Platform.isPad && Platform.OS == 'ios') {
  guidelineBaseHeight = 600;
}
if (Platform.OS == 'android' && width > 768) {
  guidelineBaseHeight = 768;
}
let guidelineBaseHeight = 680;

if (Platform.isPad && Platform.OS == 'ios') {
  guidelineBaseWidth = 800;
}
if (Platform.OS == 'android' && height > 1024) {
  guidelineBaseWidth = 1024;
}

// const _width = 1080;
// const _height = 2340;
// const scale = Math.min(deviceHeight / h2, deviceWidth / w2);

const ratio = r => width * r;
const scale = size => size + ((width / guidelineBaseWidth) * size - size) * 0.5;
// const scale = size => (width / guidelineBaseWidth) * size;

// const scaleVertical = size => (width / guidelineBaseHeight) * size;

// const scaleModerate = (size, factor = 0.5) =>
//   size + (scale(size) - size) * factor;

const widthPercentageToDP = widthPercent =>
  PixelRatio.roundToNearestPixel((width * widthPercent) / 100);

const heightPercentageToDP = heightPercent =>
  PixelRatio.roundToNearestPixel((height * heightPercent) / 100);

export {width, height, ratio, scale, widthPercentageToDP, heightPercentageToDP};
