export default {
  PRIMARY_COLOR: '#1C2845',
  SECONDARY_COLOR: '#00A6B8',
  TERTIARY_COLOR: '#F0FBFC',
  HIGHLIGHT_COLOR: '#FF055D',
  BACKGROUND_COLOR: '#FFF',

  DARK_BLUE: '#1C2845',
  TEAL: '#00A6B8',
  RED: '#FF055D',
  LIGHT_BLUE: '#F0FBFC',
};
