import React from 'react';
import {StyleSheet, Touchable, View} from 'react-native';
import HGText from 'components/HGText';
import {scale} from '../../utils/Scale';
import Row from '../Row';
import IconButton from '../IconButton';
import * as HGIcon from '../HGIcon';
import useAccount from '../../hooks/useAccount';
import Colors from '../../utils/Colors';
import {useTranslation} from 'react-i18next';
import {TouchableOpacity} from 'react-native-gesture-handler';
import useModal from 'app/hooks/useModal';
import {Logout} from 'components/HGIcon';

export default ({subTitle, title, showBack, showLogout, navigation}) => {
  const {logout} = useAccount();
  const {t} = useTranslation();
  const {showModal} = useModal();
  return (
    <Row style={styles.container}>
      {showBack && (
        <View style={styles.backIconOuterContainer}>
          <IconButton
            style={styles.backIconContainer}
            icon={<HGIcon.HeaderBack style={styles.backIcon} />}
            onPress={navigation?.goBack ?? (() => {})}
          />
        </View>
      )}
      <View
        style={[
          styles.titleContainer,
          showBack ? styles.titleContainerWithBack : {},
        ]}>
        {subTitle && (
          <View style={{marginBottom: scale(5)}}>
            <HGText variant="navigatorHeaderSubTitle">
              {subTitle.slice(0, 40)}
              {subTitle.length > 40 && '...'}
            </HGText>
          </View>
        )}
        {title ? (
          typeof title === 'function' ? (
            title()
          ) : (
            <View>
              <HGText
                variant="navigatorHeaderTitle"
                {...(title.length >= 15 ? {fontSize: scale(26)} : {})}>
                {title}
              </HGText>
            </View>
          )
        ) : (
          <View />
        )}
      </View>
      {showLogout && (
        <View style={styles.lockOuterContainer}>
          <TouchableOpacity
            onPress={() => {
              showModal({
                title: t('modal.logout.title'),
                body: t('modal.logout.body'),
                actions: [
                  {
                    label: t('modal.logout.yes'),
                    onPress: () => {
                      showModal(null);
                      logout();
                      navigation.reset({index: 0, routes: [{name: 'Login'}]});
                    },
                  },
                  {
                    label: t('modal.logout.no'),
                    variant: 'secondary',
                    onPress: () => {
                      showModal(null);
                    },
                  },
                ],
              });
              return;
            }}>
            <View style={styles.logoutButton}>
              <Logout style={styles.logoutIcon} />
            </View>
          </TouchableOpacity>
        </View>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.PRIMARY_COLOR,
    minHeight: scale(67),
  },
  backIconOuterContainer: {
    zIndex: 100,
    alignSelf: 'center',
  },
  backIconContainer: {
    backgroundColor: Colors.PRIMARY_COLOR,
    alignSelf: 'center',
    marginLeft: scale(24),
    width: scale(20),
    height: scale(20),
  },
  backIcon: {
    width: scale(20),
    height: scale(20),
  },
  lockOuterContainer: {
    zIndex: 100,
    alignSelf: 'center',
    width: scale(50),
    marginLeft: -scale(74),
    marginRight: scale(24),
  },
  logoutButton: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    borderRadius: 50,
    borderWidth: 0.1,
    borderColor: '#2b3d69',
  },
  titleContainer: {
    flex: 1,
    paddingVertical: scale(16),
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainerWithBack: {
    marginLeft: -scale(44),
  },
  logoutIcon: {
    width: scale(16),
    height: scale(20),
  },
});
