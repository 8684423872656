import {CardContainer} from 'app/components/Card';
import HGText from 'app/components/HGText';
import {scale} from 'app/utils/Scale';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';

export default ({contact}) => {
  const {t} = useTranslation();
  return (
    <CardContainer
      padded={true}
      containerStyle={{backgroundColor: '#FFF', borderWidth: 0}}>
      <View style={styles.container}>
        <View style={styles.title}>
          <HGText variant="header" upperCase={true}>
            {t('contact details')}
          </HGText>
        </View>
        <View>
          <HGText fontSize={scale(17)}>
            {contact.firstName} {contact.lastName}
          </HGText>
          <HGText fontSize={scale(17)} />
          <HGText fontSize={scale(17)}>{contact.email}</HGText>
          <HGText fontSize={scale(17)}>{contact.phone}</HGText>
        </View>
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    marginBottom: scale(20),
  },
});
