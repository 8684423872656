import React, {useCallback, useEffect, useState} from 'react';
import {View, Modal as RNModal, StyleSheet} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';
import HGButton from '../HGButton';

const Modal = ({
  visible,
  type,
  component,
  title,
  body,
  actions = [],
  onClose,
}) => {
  if (type === 'empty') {
    return (
      <RNModal animationType="fade" transparent={true} visible={visible}>
        <View style={styles.container}>{component()}</View>
      </RNModal>
    );
  }
  return (
    <RNModal animationType="fade" transparent={true} visible={visible}>
      <View style={styles.container}>
        <View style={styles.modalContainer}>
          {title && <HGText variant="header">{title}</HGText>}
          {body && <HGText>{body}</HGText>}
          {typeof component === 'function' && component()}
          <View style={styles.actionContainer}>
            {actions.length > 0 ? (
              actions.map(({label, onPress, variant = 'primary'}, i) => (
                <View
                  key={`modal-button-${i}`}
                  style={
                    i === actions.length - 1 ? {} : styles.actionButtonContainer
                  }>
                  <HGButton
                    variant={variant}
                    size="small"
                    label={label}
                    onPress={onPress || onClose}
                  />
                </View>
              ))
            ) : (
              <HGButton size="small" label="OK" onPress={onClose} />
            )}
          </View>
        </View>
      </View>
    </RNModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.35)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    padding: scale(19),
    minWidth: '75%',
    backgroundColor: '#FFF',
    borderRadius: scale(8),
  },
  actionContainer: {
    marginTop: scale(33),
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButtonContainer: {
    marginRight: scale(8),
  },
});

let ModalContext;
const {Provider} = (ModalContext = React.createContext());

let ModalProvider = ({children}) => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    type: 'default',
    title: 'Title',
    body: 'Body',
    component: null,
    actions: [],
  });
  const [modalHistory, setModalHistory] = useState([]);

  const showModal = useCallback(
    (content = false) => {
      if (content) {
        setModalHistory([...modalHistory, content]);
        setModalContent(content);
        setModal(true);
      } else {
        if (modalHistory.length > 1) {
          setModalContent(modalHistory[modalHistory.length - 2]);
          setModalHistory(modalHistory.slice(0, modalHistory.length - 1));
        } else {
          setModalHistory([]);
          setModal(false);
        }
      }
    },
    [modalHistory],
  );

  const closeModal = () => {
    showModal(false);
  };

  return (
    <Provider value={{modal, showModal, modalContent}}>
      <Modal visible={modal} {...modalContent} onClose={closeModal} />
      {children}
    </Provider>
  );
};

export {ModalContext, ModalProvider};
