import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Container from 'components/Container';
import LoginBox from './components/LoginBox';
import {scale} from '../../utils/Scale';
import HGButton from 'components/HGButton';
import {useTranslation} from 'react-i18next';
import AvatarPlaceholder from './components/AvatarPlaceholder';
import useAccount from '../../hooks/useAccount';
import useModal from '../../hooks/useModal';
import HelloGuestLogo from 'app/components/HelloGuestLogo';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const [login, setLogin] = useState({username: '', password: ''});
  const {login: _loginUser, account, loading} = useAccount();
  const {showModal} = useModal();

  const loginUser = async function () {
    if (!login.username || !login.password) return;
    try {
      const res = await _loginUser(login);
      //console.log(res);
      if (res.error) {
        showModal({
          title: t('errors.login.title'),
          body: t('errors.login.body'),
        });
        return;
      }
      if (route.params?.redirect) {
        navigation.replace(route.params?.redirect);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (account && account.loggedIn) {
      navigation.replace('Home');
    }
  }, [account, navigation]);

  return (
    <Container style={styles.container}>
      <ScrollView>
        <View style={styles.avatar}>
          <HelloGuestLogo />
        </View>
        <View style={styles.login}>
          <LoginBox onChange={setLogin} />
        </View>
        <View style={styles.button}>
          <HGButton label={t('Login')} onPress={loginUser} loading={loading} />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  avatar: {
    marginTop: scale(50),
    alignItems: 'center',
    height: 100,
    marginBottom: scale(21),
  },
  login: {
    marginBottom: scale(50),
  },
  button: {
    alignItems: 'center',
    marginBottom: scale(75),
  },
  registerLink: {marginTop: scale(10), marginBottom: scale(50)},
  notification: {
    marginTop: scale(20),
  },
});
