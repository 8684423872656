import React, {useCallback, useEffect, useState} from 'react';
import Container from 'app/components/Container';
import {StyleSheet, View} from 'react-native';
import {scale} from 'app/utils/Scale';
import {useTranslation} from 'react-i18next';
import HGTextInput from 'app/components/HGTextInput';
import HGButton from 'app/components/HGButton';
import getTicketDetails from 'app/hooks/getTicketDetails';
import useModal from 'app/hooks/useModal';
import SelectInput from 'app/components/SelectInput';
import {useForm} from 'react-hook-form';
import useQr from 'app/hooks/useQr';
export default ({route, navigation}) => {
  const {t} = useTranslation();
  const [ticketId, setTicket] = useState('');
  const {fetchTicket, loading} = getTicketDetails();
  const {showModal} = useModal();
  const {loading: loadingQr, processUserQr} = useQr();

  const {
    control,
    watch,
    setError,
    formState: {errors},
  } = useForm({mode: 'onChange', defaultValues: {manualType: 'user'}});

  const [manualType] = watch(['manualType']);

  return (
    <Container>
      <View style={styles.container}>
        <SelectInput
          label={t('type')}
          variant="form"
          form={{
            control,
            name: 'manualType',
            rules: {
              required: true,
              validate: {
                pattern: {
                  value: /^[A-Za-z0-9]*$/,
                  message: t('errors.onlyAlphabet'),
                },
              },
            },
          }}
          value={manualType}
          options={[
            {value: 'ticket', label: 'ticket'},
            {value: 'user', label: 'user'},
          ]}
        />
        <HGTextInput
          error={errors.manualType}
          label={t(`${manualType} number`)}
          onChange={setTicket}
        />
        <View style={styles.button}>
          <HGButton
            disabled={loading || loadingQr}
            loading={loading || loadingQr}
            variant="primary"
            label={t('Continue')}
            onPress={async () => {
              try {
                const ticket =
                  manualType === 'user'
                    ? await processUserQr(
                        `${
                          ticketId.indexOf('U:') !== 0
                            ? `U:${ticketId}`
                            : ticketId
                        }`,
                        route.params.eventId,
                      )
                    : await fetchTicket(ticketId);
                if (ticket.success === false) {
                  setError('manualType', {message: t('errors.invalidNumber')});
                  return;
                }
                navigation.replace('ValidateTicket', {
                  ticket,
                  eventId: route.params.eventId,
                });
              } catch (err) {
                showModal({
                  title: t('errors.ticketManualInput.title'),
                  body: t('errors.ticketManualInput.body'),
                });
              }
            }}
          />
        </View>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(120),
  },
  button: {
    marginTop: scale(40),
    alignItems: 'flex-end',
  },
});
