import React, {useState} from 'react';
import {StyleSheet, ScrollView, View} from 'react-native';

import {useTranslation} from 'react-i18next';

import {scale} from 'app/utils/Scale';
import validateTicket from 'app/hooks/validateTicket';

import Container from 'app/components/Container';
import HGButton from 'app/components/HGButton';
import ContactDetailsCard from './components/ContactDetailsCard';
import DocumentsCard from './components/DocumentsCard';
import DocumentsEmptyCard from './components/DocumentsEmptyCard';
import ContactDetailsEmptyCard from './components/ContactDetailsEmptyCard';
import TicketValidRow from './components/TicketValidRow';
import ContactValidRow from './components/ContactValidRow';
import BasicLoadingIndicator from 'app/components/BasicLoadingIndicator';

export default ({route, navigation}) => {
  const {t} = useTranslation();
  const {ticket, eventId} = route.params;

  const {visitor_name_on_ticket = {}} = ticket || {};

  const [validated, setValidated] = useState(false);
  const {loading: validating, validateTicket: _validateTicket} =
    validateTicket();
  const [checkedContact, setCheckedContact] = useState(
    ticket.profile.phone_number && ticket.email,
  );
  const [checkedDocuments, setCheckedDocuments] = useState(
    ticket.documents?.length > 0 ?? false,
  );

  const isScanTypeTicket = ticket.scanType === 'ticket'; // Ticket scan is uuid. While profile uses id
  const visitor = isScanTypeTicket
    ? {
        firstName: visitor_name_on_ticket.first_name,
        lastName: visitor_name_on_ticket.last_name,
      }
    : null;
  const contact = {
    firstName: ticket.profile.first_name,
    lastName: ticket.profile.last_name,
    phone: ticket.profile.phone_number ?? '-',
    email: ticket.email,
  };

  const nonVisualSighted =
    ticket.documents?.length > 0 && ticket.profile.phone_number && ticket.email;

  const renderTicketValidContainer = () => {
    // Only show this row if ticket is scanned not profile
    if (typeof ticket.is_valid !== 'undefined' && isScanTypeTicket) {
      return (
        <View style={styles.validityContainer}>
          <TicketValidRow
            negative={ticket.is_valid === false}
            checked={true}
            visitor={visitor}
          />
        </View>
      );
    }

    return null;
  };

  return (
    <Container>
      <ScrollView contentContainerStyle={styles.scrollContent}>
        {renderTicketValidContainer()}
        <View style={styles.validityContainer}>
          <ContactValidRow
            {...(contact && ticket.profile.phone_number && ticket.email
              ? {checked: true, accepted: true}
              : {checked: validated && checkedContact, accepted: validated})}
          />
        </View>
        <View style={styles.cardContainer}>
          {contact && ticket.profile.phone_number && ticket.email ? (
            <ContactDetailsCard contact={contact} />
          ) : (
            <ContactDetailsEmptyCard
              onCheck={setCheckedContact}
              checked={checkedContact}
            />
          )}
        </View>
        <View>
          {ticket.documents?.length > 0 ? (
            <DocumentsCard
              documents={ticket.documents}
              navigation={navigation}
            />
          ) : (
            <DocumentsEmptyCard
              onCheck={setCheckedDocuments}
              checked={checkedDocuments}
            />
          )}
        </View>
        <View style={styles.buttonsContainer}>
          {validating ? (
            <BasicLoadingIndicator />
          ) : validated ? (
            <View style={{width: '100%'}}>
              <HGButton
                fullWidth={true}
                label={t('scan more tokens')}
                onPress={() => {
                  navigation.replace('EventQrScanner', {eventId});
                }}
              />
            </View>
          ) : (
            <>
              <View style={{marginBottom: scale(10), width: '100%'}}>
                <HGButton
                  {...{
                    fullWidth: true,
                    disabled: !checkedContact || !checkedDocuments,
                    label: t('accept'),
                    onPress: async () => {
                      await _validateTicket({
                        approve: true,
                        eventId,
                        userId: ticket.id, // NOTE: It's a bit strange caling it id here, since it's the user's id
                        ticketId: ticket.uuid,
                        visuallySighted: nonVisualSighted
                          ? false
                          : !(!checkedContact || !checkedDocuments),
                      });
                      setValidated(true);
                    },
                  }}
                />
              </View>
              <View style={{width: '100%'}}>
                <HGButton
                  {...{
                    fullWidth: true,
                    label: t('reject'),
                    onPress: async () => {
                      await _validateTicket({
                        approve: false,
                        eventId,
                        userId: ticket.id,
                        ticketId: ticket.uuid,
                      });
                      setValidated(true);
                    },
                    variant: 'secondary',
                  }}
                />
              </View>
            </>
          )}
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(120),
  },
  scrollContent: {
    marginTop: scale(43),
  },
  validityContainer: {
    marginBottom: scale(40),
  },
  cardContainer: {
    marginBottom: scale(13),
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: scale(50),
    marginBottom: scale(70),
    alignItems: 'center',
  },
});
