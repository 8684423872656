import Container from 'app/components/Container';
import {Cross} from 'app/components/HGIcon';
import IconButton from 'app/components/IconButton';
import Colors from 'app/utils/Colors';
import {
  heightPercentageToDP,
  scale,
  widthPercentageToDP,
} from 'app/utils/Scale';
import React, {useEffect, useState} from 'react';
import {
  Modal,
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  Platform,
} from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
export default ({route}) => {
  const [openModal, setOpenModal] = useState(false);
  const [height, setHeight] = useState(100);

  useEffect(() => {
    Image.getSize(route.params.file, (width, height) => {
      const maxWidth = widthPercentageToDP(50);
      const ratio = height / width;
      setHeight(maxWidth * ratio);
    });
  });
  return (
    <Container>
      <Modal transparent={true} visible={openModal}>
        <ImageViewer
          imageUrls={[{url: route.params.file}]}
          onSwipeDown={() => setOpenModal(false)}
          enableSwipeDown={true}
        />
        <View style={styles.closeIcon}>
          <IconButton
            icon={
              Platform.OS === 'web' ? (
                <Cross style={styles.webIcon} />
              ) : (
                <Cross />
              )
            }
            onPress={() => setOpenModal(false)}
          />
        </View>
      </Modal>
      <View style={styles.imageContainer}>
        <TouchableOpacity onPress={() => setOpenModal(true)}>
          <Image
            source={{uri: route.params.file}}
            style={[styles.imageThumbnail, {height}]}
          />
        </TouchableOpacity>
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  imageThumbnail: {
    width: widthPercentageToDP(50),
    height: heightPercentageToDP(50),
  },
  imageContainer: {
    marginTop: scale(50),
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    left: scale(10),
    top: scale(10),
  },
  webIcon: {
    width: scale(10),
    height: scale(10),
  },
});
