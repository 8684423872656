import React from 'react';
import {View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Header from './Header';
import {scale} from '../../../../utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import BasicLoadingIndicator from 'components/BasicLoadingIndicator';
import FullWidthEventCard from 'app/components/Card/FullWidthEventCard';

export default ({navigation, header, events, loading}) => {
  const {t, i18n} = useTranslation();
  const currentLang = i18n.language;
  const renderItem = ({
    title,
    date_at: date,
    assets,
    start_at: startAt,
    admission_at: admission,
    id: eventId,
  }) => {
    const image = assets[0]?.public_path ?? ''; //TODO: DEFAULT 404 NO FILE HERE
    return (
      <TouchableOpacity
        key={eventId}
        style={styles.card}
        onPress={() => {
          // TODO: Replace with proper eventId;
          navigation.navigate('EventConnect', {
            eventId,
          });
        }}>
        <FullWidthEventCard
          {...{title: title[currentLang], date, image, admission, startAt}}
        />
      </TouchableOpacity>
    );
  };
  return (
    <View style={styles.container}>
      {header && <Header text={header.toUpperCase()} />}
      <ScrollView>
        {loading && <BasicLoadingIndicator />}
        {!loading && events.length === 0 && (
          <HGText>{t('No results found')}</HGText>
        )}
        {events.map(event => renderItem(event))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(36),
  },
});
