import {useEffect, useState} from 'react';
import MOCK_TICKETS from '../utils/mock_data/tickets.json';

export default params => {
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState(null);

  async function fetchTicket(ticketId) {
    setLoading(true);
    try {
      // TODO: INTEGRATE WITH API
      return new Promise((resolve, reject) =>
        setTimeout(() => {
          //MOCK FETCHING
          const _ticket =
            ticketId === '1234' ? MOCK_TICKETS[1] : MOCK_TICKETS[0];
          if (!ticketId) {
            setError('error');
            reject('error');
          } else {
            setTicket(_ticket);
            resolve(_ticket);
          }
          setLoading(false);
        }, 1000),
      );
    } catch (err) {
      setLoading(false);
      setError(err);
    } finally {
    }
  }
  return {ticket, error, fetchTicket, loading};
};
