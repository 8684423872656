import React from 'react';
import {View, Text, Platform} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {SafeAreaProvider, SafeAreaView} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import MainNavigator from './app/navigation/MainNavigator';
import store, {persistor} from './app/state';
import {ModalProvider} from './app/components/Modal';
import appName from './app.json';
import Splash from './app/views/Splash';

const App = () => {
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <PersistGate
          loading={<Splash disableRedirect={true} />}
          persistor={persistor}>
          <ModalProvider>
            <SafeAreaView style={{flex: 1}}>
              <NavigationContainer
                documentTitle={{
                  formatter: (options, route) =>
                    Platform.OS === 'web'
                      ? appName.displayName
                      : options?.title ?? route?.name,
                }}>
                <MainNavigator />
              </NavigationContainer>
            </SafeAreaView>
          </ModalProvider>
        </PersistGate>
      </Provider>
    </SafeAreaProvider>
  );
};

export default App;
