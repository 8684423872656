import config from '../config';
import {useState} from 'react';
import {useSelector} from 'react-redux';

export default () => {
  const [loading, setLoading] = useState(false);
  const {accessToken, tenantId} = useSelector(state => state.account);

  const processUserQr = async (uuid, eventId) => {
    setLoading(true);

    try {
      const res = await fetch(
        `${config.ENDPOINT_USER_QR}/${uuid}?event_id=${eventId}`,
        {
          headers: {'X-AUTH-TOKEN': accessToken, 'X-TENANT-ID': tenantId},
        },
      );
      const body = await res.json();

      return {
        ...body,
        success: res.status === 200,
        scanType: getScanType(uuid),
      };
    } catch (error) {
      return {success: false, error};
    } finally {
      setLoading(false);
    }
  };

  const getScanType = (uuid = '') => {
    // TODO: Might need to put this into constants
    if (uuid.startsWith('T:')) {
      return 'ticket';
    } else if (uuid.startsWith('U:')) {
      return 'user';
    }

    return null;
  };

  return {processUserQr, loading};
};
