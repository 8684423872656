import React, {useRef} from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  TouchableWithoutFeedback,
} from 'react-native';
import {scale} from '../../utils/Scale';
import HGText from 'components/HGText';
import Colors from '../../utils/Colors';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';

const ErrorMessage = ({type, message}) => {
  const {t} = useTranslation();
  const getErrorMessage = () => {
    switch (type) {
      case 'required':
        return t('errors.required');
      default:
        return t('errors.default');
    }
  };

  return (
    <HGText style={styles.errorContainer} variant="error">
      {message || getErrorMessage()}
    </HGText>
  );
};

const HGTextInput = ({
  label,
  variant,
  labelEndComponent,
  inputEndComponent,
  onChange: _onChange,
  value,
  error,
  form,
  inputProps = {},
}) => {
  function onChange(val) {
    _onChange && _onChange(val);
  }

  const inputRef = useRef(null);

  const rootStyle = [];
  switch (variant) {
    case 'form':
      rootStyle.push(styles.formRootContainer);
  }

  return (
    <View style={rootStyle}>
      {label && (
        <View style={styles.labelContainer}>
          <HGText variant="inputLabel">{label}</HGText>
          {labelEndComponent && (
            <>
              <View style={styles.emptySeparator} />
              {labelEndComponent()}
            </>
          )}
        </View>
      )}
      <View
        style={[
          styles.inputContainer,
          error ? styles.errorInputContainer : {},
        ]}>
        <TouchableWithoutFeedback onPress={() => inputRef.current?.focus()}>
          {form ? (
            <Controller
              {...form}
              render={({field: {onChange, onBlur, value: _value = ''}}) => (
                <TextInput
                  {...inputProps}
                  value={_value}
                  ref={inputRef}
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                />
              )}
            />
          ) : (
            <TextInput
              {...inputProps}
              value={value}
              ref={inputRef}
              style={styles.input}
              onChangeText={onChange || (() => {})}
            />
          )}
        </TouchableWithoutFeedback>
        {inputEndComponent && inputEndComponent()}
      </View>
      {error ? (
        <ErrorMessage type={error.type} message={error.message} />
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  emptySeparator: {flex: 1},
  formRootContainer: {
    marginBottom: scale(15),
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
    marginBottom: scale(6),
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: scale(18),
    borderWidth: 0.25,
    borderColor: Colors.SECONDARY_COLOR,
    backgroundColor: '#FFF',
    height: scale(36),
    paddingHorizontal: scale(16),
  },
  errorInputContainer: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_COLOR,
  },
  input: {height: scale(36), flex: 1},
});

export default HGTextInput;
