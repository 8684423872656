import React, {useEffect, useState} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Splash from '../views/Splash';
import {Platform, View} from 'react-native';
import {mobileCheck} from 'utils/WebUtils';
import Login from 'app/views/Login';
import FrontNavigationHeader from 'components/FrontNavigationHeader';
import {useTranslation} from 'react-i18next';
import Home from 'app/views/Home';
import EventConnect from 'app/views/EventConnect';
import QrCodeScanner from 'app/views/QrCodeScanner';
import EventConnectError from 'app/views/EventConnectError';
import EventManualInput from 'app/views/EventManualInput';
import ValidateTicket from 'app/views/ValidateTicket';
import ImageDocumentViewer from 'app/views/ImageDocumentViewer';
import useAccount from 'app/hooks/useAccount';
import config from 'app/config';

const Stack = createStackNavigator();

export default ({}) => {
  const {t} = useTranslation();
  const [initialize, setInitialize] = useState(false);
  const {account, loading} = useAccount();

  if (Platform.OS === 'web' && !mobileCheck()) {
    // redirect if viewing on a desktop
    window.location.replace(config.REDIRECT_NOT_MOBILE);
    return <View />;
  }

  useEffect(() => {
    if (!initialize && account && account.loggedIn && account.accessToken) {
      setInitialize(true);
    }
  }, [initialize, account]);

  return loading ? (
    <Splash disableRedirect={true} />
  ) : (
    <Stack.Navigator
      initialRouteName={'Splash'}
      screenOptions={{title: 'Kulturboden'}}>
      <Stack.Screen
        name="Splash"
        component={Splash}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader navigation={navigation} title={t('login')} />
          ),
        }}
      />
      <Stack.Screen
        name="Home"
        component={Home}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showLogout={true}
              navigation={navigation}
              title={t('home.navigation.title')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="EventConnect"
        component={EventConnect}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('home.navigation.title')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="EventQrScanner"
        component={QrCodeScanner}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('Scan QR code')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="EventQrScannerError"
        component={EventConnectError}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('home.navigation.title')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="EventManualInput"
        component={EventManualInput}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('manual input')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ValidateTicket"
        component={ValidateTicket}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('home.navigation.title')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="ImageDocumentViewer"
        component={ImageDocumentViewer}
        options={{
          header: ({navigation, scene: {route}}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={route.params.title}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};
