import Config from '../../config';

export const loginUser = async ({username, password}) => {
  try {
    const res = await fetch(Config.ENDPOINT_LOGIN, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          username,
          password,
        },
      }),
    });

    if (res.status === 400) {
      // invalid username / password? Failed to login
      throw Error('fail_login');
    }

    const json = await res.json();
    return {
      accessToken: json.auth_token,
      tenantId: json.tenant_uuid,
      user: {email: json.email, ...json.profile},
    };
  } catch (err) {
    return {success: false, error: err};
  }
};
