import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from '../lang/en.json';
import de from '../lang/de.json';
import MAIN_CONFIG from './index';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  fallbackLng: 'en',
  lng: MAIN_CONFIG.APP_LANGUAGE,
  debug: __DEV__,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
