import * as RNLocalize from 'react-native-localize';

const userLocales = RNLocalize.getLocales();
const userLocale = userLocales[0] || {languageCode: 'de'};

const APP_LANGUAGE =
  userLocale.languageCode !== 'de' && userLocale.languageCode !== 'en'
    ? 'de'
    : userLocale.languageCode;

const BASE_CONFIG = ENV_CONFIG => ({
  BASE_URL: 'https://api.stg.helloguest.io/bo/v1',
  ENDPOINT_LOGIN: ENV_CONFIG.BASE_URL + '/sessions',
  ENDPOINT_USER_QR: ENV_CONFIG.BASE_URL + '/ticket_platform/doorman_scan',
  ENDPOINT_VALIDATE_TICKET:
    ENV_CONFIG.BASE_URL + '/ticket_platform/validate_user_registration',
  APP_LANGUAGE,
  REDIRECT_NOT_MOBILE: 'https://www.helloguest-solutions.com',
});

export default (ENV_CONFIG, isProd) => ({
  ...BASE_CONFIG(ENV_CONFIG),
  ...ENV_CONFIG,
  isProd,
});
