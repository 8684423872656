/**
 * @format
 */

import {AppRegistry} from 'react-native';
import App from './App';
import {name as appName} from './app.json';
import './app/config/i18n';
import {Settings} from 'luxon';
Settings.defaultLocale = 'de';

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});

// Allow HMR to work
if (module.hot) {
  module.hot.accept();
}
