import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser, logoutUser} from '../../state/account';

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const account = useSelector(state => state.account);

  async function login({username, password}) {
    setLoading(true);
    try {
      const res = await dispatch(loginUser({username, password}));
      if (res.error) {
        return {error: true};
      }
      return {success: true};
    } catch (err) {
      return {error: true};
    } finally {
      setLoading(false);
    }
  }

  async function logout() {
    dispatch(logoutUser());
  }

  return {login, logout, account, loading};
};
