import {createSlice} from '@reduxjs/toolkit';
import {Platform} from 'react-native';
import persistReducer from 'redux-persist/es/persistReducer';
import {loginUser as _loginUser} from './helpers';
import persistConfig from './persistConfig';

const initialState = {
  user: {},
  loggedIn: false,
  accessToken: '',
  tenantId: '',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.loggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.tenantId = action.payload.tenantId;
    },
    logout: (state, action) => {
      state.user = {};
      state.loggedIn = false;
      state.accessToken = '';
      state.tenantId = '';
    },
  },
});

const actions = accountSlice.actions;

// Thunks
export const loginUser =
  ({username, password}) =>
  async dispatch => {
    try {
      const payload = await _loginUser({username, password});
      if (!payload.error) {
        dispatch(actions.login(payload));
        return {success: true};
      } else {
        return {success: false, error: payload.error};
      }
    } catch (err) {}
  };

export const logoutUser = () => async dispatch => {
  dispatch(actions.logout());
};

export default persistReducer(persistConfig, accountSlice.reducer);
