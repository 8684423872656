import {useEffect, useMemo, useState} from 'react';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {initializeData} from '../state/events';
import config from 'app/config';
import {useNavigation} from '@react-navigation/native';

export default params => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const {
    loading,
    events: _events,
    categories,
    initialize,
  } = useSelector(state => state.events);

  const {genreId} = params ? params : {};

  const events = useMemo(() => {
    const currentDate = DateTime.local().startOf('day').toMillis();
    const liveEvents = _events
      .filter(({date_at}) => {
        const eventDate = DateTime.fromJSDate(new Date(date_at))
          .startOf('day')
          .toMillis();
        return config.isProd
          ? eventDate === currentDate
          : eventDate >= currentDate;
      })
      .sort(({date_at}) => new Date(date_at) - currentDate);
    return genreId
      ? liveEvents.filter(({categories}) => {
          let isInFilter = false;
          categories.forEach(({id}) => {
            if (id === genreId) {
              isInFilter = true;
            }
          });
          return isInFilter;
        })
      : liveEvents;
  }, [_events, genreId]);

  useEffect(() => {
    if (!initialize) {
      dispatch(initializeData(navigation));
    }
  }, [dispatch, initialize, navigation]);

  return {events, categories, loading};
};
