import Container from 'components/Container';
import React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import Colors from 'utils/Colors';

export default ({}) => {
  return (
    <Container style={styles.container}>
      <ActivityIndicator color={Colors.PRIMARY_COLOR} />
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
