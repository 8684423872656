import React, {useState} from 'react';
import {View, Text, ScrollView} from 'react-native';
import Container from 'components/Container';
import {useTranslation} from 'react-i18next';
import InfiniteVerticalCategoryList from './components/InfiniteVerticalCategoryList';
import getEvents from 'hooks/getEvents';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {events, loading} = getEvents();

  return (
    <Container>
      <ScrollView>
        <InfiniteVerticalCategoryList
          loading={loading}
          navigation={navigation}
          events={events}
        />
      </ScrollView>
    </Container>
  );
};
