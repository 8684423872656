import React from 'react';
import {Image} from 'react-native';

export default () => (
  <Image
    style={{height: '100%', width: '100%'}}
    resizeMode="contain"
    source={require('../../assets/images/logo.png')}
  />
);
