import React, {useEffect, useState} from 'react';
import Container from 'app/components/Container';
import {StyleSheet, View, ScrollView} from 'react-native';
import {scale, widthPercentageToDP} from 'app/utils/Scale';
import {useTranslation} from 'react-i18next';
import HGButton from 'app/components/HGButton';
import HGText from 'app/components/HGText';
import {CrossFillRed} from 'app/components/HGIcon';
export default ({route, navigation}) => {
  const {t} = useTranslation();

  return (
    <Container>
      <ScrollView>
        <View style={styles.errorContainer}>
          <View>
            <CrossFillRed style={{width: 150, height: 150}} />
          </View>
          <View style={styles.errorText}>
            <HGText fontSize={scale(24)}>{t('Ticket not readable')}</HGText>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <HGButton
              fullWidth={true}
              label={t('try again')}
              onPress={() => navigation.replace('EventQrScanner', route.params)}
            />
          </View>
          {/* <View style={styles.button}>
            <HGButton
              fullWidth={true}
              variant="secondary"
              label={t('manual input')}
              onPress={() =>
                navigation.replace('EventManualInput', route.params)
              }
            />
          </View> */}
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  errorContainer: {
    marginTop: scale(75),
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorText: {
    marginTop: scale(40),
  },
  buttonContainer: {
    flex: 1,
    marginTop: scale(50),
    alignItems: 'center',
    marginBottom: scale(75),
  },
  button: {
    width: widthPercentageToDP(75),
    marginBottom: scale(10),
  },
});
